import React, {FC, PropsWithChildren, useEffect, useState} from 'react';
import {useTranslation} from 'react-i18next';
import {TypedUseSelectorHook, useDispatch, useSelector} from 'react-redux';
import {AppDispatch, RootState} from '../redux/store';
import {
    ARABIC,
    ENGLISH,
    LANGUAGE,
    LOGIN_STATE_KEY,
    PERSIAN,
    SH_TITLE,
    THEME,
    THEME_DARK,
    THEME_LIGHT,
    THEME_STATE_KEY,
    TOKEN
} from '../services/CONSTANTS';
import {getAppLanguage, getLastTab, saveAppLanguage, saveLoginParams} from '../services/localStorage.service';
import ReactGA from 'react-ga4';
import {BUTTON_CLICK, BUTTON_CLICK_BACK} from '../analytics/CONSTANTS';
import {toPersianNumber} from '../utils/number.utils';

import {ComponentService} from './service/component.service';
import {LOGIN_ACTION} from '../../page/profile/components/Login/redux/loginAction';
import {LOGIN_STATE} from '../../page/profile/components/Login/CONSTANTS';
import {FINANCIAL_HISTORY, NEWS} from '../navigation/CONSTANTS';
import {FavoriteDatabaseHelper} from '../../page/Home/components/MenuBuilder/widget/favorite/database/favorite.database.helper';
import {RowComponent} from './base/row.component';
import {MESSAGE_ACTION} from '../redux/reducers/messageActions';
import LayoutDrawer from './layoutDrawer.component';
import {USER_FAVORITE_FULL_IMAGE, USER_FAVORITE_IMAGE, USER_SETTING_IMAGE} from '../../page/profile/CONSTANTS';
import {TitleImageRowComponent} from './titleImageRowComponent';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import {ColumnComponent} from './base/column.component';
import {Breadcrumbs, SwipeableDrawer} from '@mui/material';
import {useRouter} from 'next/router';
import useLocalStorageState from '../utils/useLocalStorage';
import {motion} from 'framer-motion';
import NavigateBeforeIcon from '@mui/icons-material/NavigateBefore';
import {CONTAINER_ACTION} from '../../page/Main/redux/mainAction';
import {BackIconComponent} from "./backIcon.component";
import {NextSeo} from 'next-seo';
import {PAGE_GAME_KEY, PAGE_HOME_KEY, PAGE_STORE_KEY, PAGE_TOOLS_KEY, PAGE_VIDEO_KEY} from "../../page/Main/CONSTANTS";

interface containerProps {
    hasResponsivePadding?: boolean;
    onSettingClick?: any;
    onHasToolbar?: any;
}

export const ContainerComponent: FC<PropsWithChildren<containerProps>> = (props) => {
    const router = useRouter();
    const {t} = useTranslation();
    let dispatch = useDispatch<AppDispatch>();
    const [resultOpen, setResultOpen] = useState(false);
    const [isLogin, setLogin] = useLocalStorageState<boolean>(LOGIN_STATE_KEY, false);
    const {i18n} = useTranslation();
    const [hasToolbar, setHasToolbar] = useState(false);
    const [pageImage, setPageImage] = useState<string>();
    const [pageTitle, setPageTitle] = useState<string>();
    const [shortcutFavoriteExist, setShortcutFavoriteExist] = useState(false);

    const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
    const shState = useAppSelector((state) => state.shReducer);
    const containerState = useAppSelector((state) => state.containerReducer);
    const favHelper = new FavoriteDatabaseHelper();
    const [isDark, setDark] = useLocalStorageState<boolean>(THEME_STATE_KEY, false);

    useEffect(() => {
        if (shState && shState.item) {
            favHelper.getFavoriteItem(shState.item.id).then((fav) => {
                if (fav && fav.id) {
                    setShortcutFavoriteExist(true);
                }
            });
        }
    }, [shState]);

    useEffect(() => {
        if (router.query[TOKEN]) {
            const componentService = new ComponentService();
            componentService.getPrimaryToken(router.query[TOKEN]?.toString() ?? '').subscribe((data) => {
                if (data && data.data && data.data.access) {
                    setLogin(true);
                    saveLoginParams(data.data.access, data.data.refresh, '', data.data.user_id);
                    dispatch({
                        type: LOGIN_ACTION,
                        openLoginPage: false,
                        state: LOGIN_STATE
                    });
                }
                //TODO sajad
                // router.query.delete(TOKEN);
                router.replace(router.query.toString());
            });
        }
        if (router.query[THEME]) {
            if (router.query[THEME]?.toString() == THEME_DARK) {
                setDark(true);
            } else if (router.query[THEME]?.toString() == THEME_LIGHT) {
                setDark(false);
            }
        }
        if (router.query[LANGUAGE]) {
            if (router.query[LANGUAGE]?.toString() == ENGLISH) {
                saveAppLanguage(ENGLISH);
                i18n.changeLanguage(ENGLISH);
            } else if (router.query[LANGUAGE]?.toString() == PERSIAN) {
                saveAppLanguage(PERSIAN);
                i18n.changeLanguage(PERSIAN);
            } else if (router.query[LANGUAGE]?.toString() == ARABIC) {
                saveAppLanguage(ARABIC);
                i18n.changeLanguage(ARABIC);
            } else if (i18n.language === ENGLISH) {
                saveAppLanguage(ENGLISH);
                i18n.changeLanguage(ENGLISH);
            }
        } else {
            if (getAppLanguage() === ENGLISH) {
                if (i18n.language != ENGLISH) {
                    i18n.changeLanguage(ENGLISH);
                }
            }
        }
        if (router.query[SH_TITLE]) {
            if (router.pathname.includes("/t/")) {
                if (router.pathname.split("/t/").length > 1) {
                    setPageImage(`https://tlbx.ir/image/png_ic_${router.pathname.split("/t/")[1].split("/")[0]}.png`)
                }
            }
            setPageTitle(router.query[SH_TITLE]?.toString() as string);
            setHasToolbar(true);
            //TODO sajad
            // props.onHasToolbar?props.onHasToolbar(true):null
        }
    }, [router.query]);

    function backButtonClick() {
        return () => {
            dispatch({
                type: CONTAINER_ACTION,
                state: ''
            });
            ReactGA.event({
                category: BUTTON_CLICK,
                action: BUTTON_CLICK_BACK
            });
            if (router.pathname.includes(FINANCIAL_HISTORY + '/')) {
                router.replace({pathname: FINANCIAL_HISTORY, search: `shTitle=${t('payReceipt')}`});
            } else {
                router.replace('/');
            }
        };
    }

    function appBackButtonClick() {
        if (router.pathname.includes(FINANCIAL_HISTORY + '/')) {
            router.replace(FINANCIAL_HISTORY, {search: `shTitle=${t('payReceipt')}`});
        } else if (router.pathname.includes(NEWS)) {
            if (router.asPath.includes("post")) {
                if (router.asPath.includes("selected")) {
                    router.replace({pathname: NEWS, search: hasToolbar?`shTitle=${t('shName.news')}`:""});
                } else {
                    router.replace({pathname: router.asPath.split("post")[0], search: hasToolbar?`shTitle=${t('shName.news')}`:""});
                }
            } else {
                router.back();
            }
        } else {
            router.back();
        }
    }

    function addToolToFavorite() {
        setShortcutFavoriteExist(true);
        favHelper.addFavoriteItem(shState.item, false);
        dispatch({
            type: MESSAGE_ACTION,
            message: t('fav.addToFavorite'),
            sort: 'success'
        });
    }

    function removeToolFromFavorite() {
        setShortcutFavoriteExist(false);
        favHelper.deleteFavoriteItem(shState.item, true);
        dispatch({
            type: MESSAGE_ACTION,
            message: t('fav.deleteFromFavorite'),
            sort: 'info'
        });
    }

    function middleRouteClick() {
        dispatch({
            type: CONTAINER_ACTION,
            state: ''
        });
        appBackButtonClick()
    }

    const variants = {
        show: {
            opacity: 1,
            x: 0,
            transition: {
                ease: 'easeOut',
                duration: 0.3
            }
        },
        hide: {
            x: 50,
            opacity: 0
        }
    };

    function getTabName() {
        switch (getLastTab()) {
            case PAGE_HOME_KEY:
                return t('main')
            case PAGE_TOOLS_KEY:
                return t('tools')
            case PAGE_STORE_KEY:
                return t('store')
            case PAGE_VIDEO_KEY:
                return t('videoPlus')
            case PAGE_GAME_KEY:
                return t('game')
            default:
                return t('main')
        }
    }

    function getRouterPath() {
        let routeBase = "test";
        if (router.pathname.includes("/t/")) {
            if (router.pathname.split("/t/").length > 1) {
                routeBase = router.pathname.split("/t/")[1].split("/")[0];
            }
        }
        return routeBase;
    }

    return (
        <div className="background flex flex-col w-full">
            {!router.asPath.includes("/post/")?<NextSeo
                title={pageTitle ?? `${t('appName')}`}
                description={i18n.exists(`shDesc.${getRouterPath()}`) ? t(`shDesc.${getRouterPath()}`) : t('appDesc')}
                openGraph={{
                    type: 'website',
                    locale: 'fa_IR',
                    url: 'https://tlbx.ir/',
                    siteName: t('appName'),
                    images: [
                        {
                            url: pageImage ?? 'https://tlbx.ir/icon.png',
                            width: 192,
                            height: 192,
                            type: 'image/png'
                        }
                    ],
                }}
                themeColor={'#000'}
            />:null}
            {hasToolbar ? (
                <div
                    className={`flex flex-row mainToolbar p-2 totalCenter backgroundCard z-10 sticky top-0 md:top-16`}
                    style={{caretColor: 'transparent'}}
                >
                    <Breadcrumbs
                        className={`w-full px-2 hidden md:block z-30`}
                        separator={<NavigateBeforeIcon fontSize="small"/>}
                        aria-label="breadcrumb"
                    >
                        <div className={'text-bold pointer'} onClick={backButtonClick()}>
                            {getTabName()}
                        </div>
                        {containerState.state ? (
                            <motion.div
                                initial={'hide'}
                                animate={'show'}
                                exit={'hide'}
                                variants={variants}
                                className={'flex flex-col items-center'}
                                transition={{
                                    type: 'spring',
                                    damping: 20,
                                    stiffness: 250,
                                    duration: 1
                                }}
                            >
                                <div className={'text-bold pointer'} onClick={() => middleRouteClick()}>
                                    {containerState.state}
                                </div>
                            </motion.div>
                        ) : null}
                        <div className="mText text-lg text-ellipsis max-h-8 text-bold mTextBlue grow overflow-hidden">
                            {toPersianNumber(pageTitle ?? "")}
                        </div>
                    </Breadcrumbs>
                    <div className={"totalCenter flex-row w-full flex md:hidden z-30"}>
                        <BackIconComponent onClick={() => appBackButtonClick()}/>
                        <div
                            className="mText text-lg text-ellipsis max-h-8 text-bold px-1 mTextBlue grow overflow-hidden">{toPersianNumber(pageTitle ?? "")}</div>
                    </div>
                    {shState && shState.item ?
                        <MoreVertIcon className="mx-2 pointer" onClick={() => setResultOpen(true)}/> : null}
                </div>
            ) : null}
            <motion.div
                initial={{opacity: 0}}
                animate={{opacity: 1}}
                className={`w-full flex flex-col items-center`}
                transition={{
                    type: 'tween',
                    duration: 0.4
                }}>
                <div
                    className={`${
                        props.hasResponsivePadding ? 'containerFullWidth' : 'w-full'
                    }`}>
                    {props.children}
                </div>
            </motion.div>
            <SwipeableDrawer
                anchor={'bottom'}
                open={resultOpen}
                disableSwipeToOpen={true}
                PaperProps={{square: false, elevation: 0, style: {backgroundColor: 'transparent'}}}
                onOpen={() => setResultOpen(true)}
                onClose={() => setResultOpen(!resultOpen)}
            >
                <LayoutDrawer>
                    <ColumnComponent>
                        <RowComponent className="p-1 totalCenter mt-5">
                            {shortcutFavoriteExist ? (
                                <TitleImageRowComponent
                                    onClick={() => removeToolFromFavorite()}
                                    title={t('removeFromFavorite')}
                                    image={USER_FAVORITE_FULL_IMAGE}
                                />
                            ) : (
                                <TitleImageRowComponent
                                    onClick={() => addToolToFavorite()}
                                    title={t('addToFavorite')}
                                    image={USER_FAVORITE_IMAGE}
                                />
                            )}
                        </RowComponent>
                        {shState && shState.hasSetting ? (
                            <TitleImageRowComponent onClick={props.onSettingClick} title={t('setting')}
                                                    image={USER_SETTING_IMAGE}/>
                        ) : null}
                    </ColumnComponent>
                </LayoutDrawer>
            </SwipeableDrawer>
        </div>
    );
};
