import {Observable} from 'rxjs';
import Api from '../../services/Api';
import {GET_PRIMARY_URL} from './CONSTANTS';
import {VerifyMainModel} from '../../../page/profile/components/Login/login.model';

export class ComponentService {
  public getPrimaryToken(key: string): Observable<VerifyMainModel | void> {
    return Api.post<VerifyMainModel>(GET_PRIMARY_URL, {
      key: key
    });
  }
}
