import {menuDb} from '../../../database/menuDB';
import {MenuBuilderShortcutModel} from '../../../../../../Main/main.model';
import {Favorite} from '../../../database/models/menudb.favorite';

export class FavoriteDatabaseHelper {
  public async addFavoriteItems(items: MenuBuilderShortcutModel[]) {
    try {
      for (const shortcut of items) {
        await menuDb.favorite.get(shortcut.id).then(async (fav) => {
          if (fav) {
            if (!(fav.isDelete ?? false)) {
              await this.addFavItem(shortcut, fav.date ?? new Date().getTime());
            }
          } else {
            await this.addFavItem(shortcut, null);
          }
        });
      }
    } catch (error) {
      console.log(`Failed to add ${error}`);
    }
  }

  public async getFavoriteItem(id: number): Promise<Favorite | undefined> {
    return menuDb.favorite.get(id);
  }

  public async addFavoriteItem(shortcut: MenuBuilderShortcutModel, isSync: boolean) {
    try {
      const shId = await menuDb.favorite.put({
        id: shortcut.id,
        titleFa: shortcut?.title?.fa ?? '',
        titleEn: shortcut?.title?.en ?? '',
        unique_name: shortcut.unique_name,
        cover_url: shortcut.cover_url ?? '',
        icon_url: shortcut.icon_url ?? '',
        is_enabled: shortcut.is_enabled ?? true,
        login_required: shortcut.login_required ?? false,
        tooltip: shortcut.tooltip ?? '',
        tooltip_color: shortcut.tooltip_color ?? '',
        icon_color: shortcut.icon_color ?? '',
        date: new Date().getTime(),
        url: shortcut.url ?? '',
        isSync: isSync,
        isDelete: false
      });
    } catch (error) {
      console.log(`Failed to add ${error}`);
    }
  }

  public async deleteFavoriteItem(id: number, isDelete: boolean) {
    try {
      await menuDb.favorite.update(id, {
        isDelete: isDelete
      });
    } catch (error) {
      console.log(`Failed to add ${error}`);
    }
  }

  public async updateFavoriteSync(id: number, isSync: boolean) {
    try {
      await menuDb.favorite.update(id, {
        isSync: isSync
      });
    } catch (error) {
      console.log(`Failed to add ${error}`);
    }
  }

  public async deleteFavorite(id: number) {
    try {
      await menuDb.favorite.delete(id);
    } catch (error) {
      console.log(`Failed to add ${error}`);
    }
  }

  //
  public favoriteMapper(shortcuts: (Favorite | undefined)[]): MenuBuilderShortcutModel[] {
    return shortcuts.map((shortcut) => {
      return {
        id: shortcut?.id ?? 0,
        title: shortcut?.titleFa ? { fa: shortcut?.titleFa ?? '', en: shortcut?.titleEn ?? '' } : null,
        url: shortcut?.url ?? '',
        cover_url: shortcut?.cover_url ?? '',
        icon_url: shortcut?.icon_url ?? '',
        tooltip: shortcut?.tooltip ?? '',
        icon_color: shortcut?.icon_color ?? '',
        is_enabled: shortcut?.is_enabled ?? true,
        login_required: shortcut?.login_required ?? false,
        unique_name: shortcut?.unique_name ?? '',
        tooltip_color: shortcut?.tooltip_color ?? '',
        isDelete: shortcut?.isDelete ?? false
      };
    });
  }

  public async getFavoriteItems(): Promise<Favorite[]> {
    return menuDb.favorite.orderBy('date').reverse().toArray();
  }

  private async addFavItem(shortcut: MenuBuilderShortcutModel, date: number | null) {
    await menuDb.favorite.put({
      id: shortcut.id,
      titleFa: shortcut?.title?.fa ?? '',
      titleEn: shortcut?.title?.en ?? '',
      unique_name: shortcut.unique_name,
      cover_url: shortcut.cover_url ?? '',
      icon_url: shortcut.icon_url ?? '',
      is_enabled: shortcut.is_enabled ?? true,
      login_required: shortcut.login_required ?? false,
      tooltip: shortcut.tooltip ?? '',
      date: date ?? new Date().getTime(),
      tooltip_color: shortcut.tooltip_color ?? '',
      icon_color: shortcut.icon_color ?? '',
      url: shortcut.url ?? '',
      isSync: true,
      isDelete: false
    });
  }
}
